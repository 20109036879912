import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PRODUCT_ENDPOINT } from '@/api/APIClient';
import { mapProduct } from '@/api/product/productMapper';
import { ExperienceSwitch } from '@/components/experience-switch/ExperienceSwitch';
import { Loading } from '@/components/loading/Loading';
import { PageContainer } from '@/components/page-container/PageContainer';
import { ProductIntroduction } from '@/components/product-introduction/ProductIntroduction';
import { ProductTabs } from '@/components/product-tabs/ProductTabs';
import { SelectLanguages } from '@/components/select-languages/SelectLanguages';
import { TrackPage } from '@/components/track-page/TrackPage';
import { TRACK_PAGE_NAMES } from '@/constants/tracking';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { ExperienceProvider } from '@/contexts/ExperienceContext';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductPage: FunctionComponent = () => {
  const { locale = '', productId = '' } = useRouterContext();
  const navigate = useNavigate();

  const {
    data: product,
    error: productError,
    fetch: fetchProduct,
    isLoading: isProductLoading,
  } = useAPI({ productId, locale, mapper: mapProduct, url: PRODUCT_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetchProduct();
    }
  }, [fetchProduct, productId]);

  if (isProductLoading) {
    return <Loading />;
  }

  if (productError) {
    navigate('/not-found', { replace: true });
    return null;
  }

  if (!product) {
    return null;
  }

  return (
    <div className="relative isolate z-0 min-h-screen pb-80">
      <ExperienceProvider product={product}>
        <TrackPage
          locale={locale}
          pageName={TRACK_PAGE_NAMES.PRODUCT_PAGE}
          params={{
            resort_code: productId,
            resort_country: product.country.id,
            site_section: 'qr_resort_discovery',
          }}
        />
        <SelectLanguages className="absolute end-0 right-0 top-0 m-20" />
        <PageContainer as="header" className="max-w-[840px] pb-40 pt-80" role="banner">
          <ProductIntroduction
            product={{
              comfort: product.comfort,
              description: product.description,
              id: product.id,
              name: product.name,
            }}
          />
        </PageContainer>
        <PageContainer className="flex justify-center">
          <ExperienceSwitch comfort={product.comfort} />
        </PageContainer>
        <PageContainer as="main" className="px-0 pb-40 pt-20 lg:px-20" role="main">
          <ProductTabs product={product} />
        </PageContainer>
      </ExperienceProvider>
    </div>
  );
};
