import { IMAGE_TYPES } from '@/constants/image';
import { isCruise } from '@/helpers/product';

type Params = { locale: string; productId: string };

export const getLocale = (locale: string) => (locale === 'fr-CA' ? 'fr-FR' : locale);

const mapLPEventTranslation = (translation: DirectusLPEventTranslation) => ({
  subtitle: translation?.subtitle || '',
  text: translation?.description || '',
  title: translation?.title || '',
});

const mapLPEvent = (e: DirectusLPEvent): LPEvent => {
  const links = [
    ...(e?.links || []).map(({ translations }) => translations?.[0]),
    e?.translations?.[0]?.link
      ? {
          label: e?.translations?.[0]?.link_label || '',
          url: e?.translations?.[0]?.link,
        }
      : null,
  ].filter(Boolean) as LPEventLink[];

  return {
    image: {
      resolver: IMAGE_TYPES.DIRECTUS,
      src: e?.image?.id || '',
    },
    links,
    ...mapLPEventTranslation(e?.translations?.[0]),
  };
};

const mapLPEvents = (events?: DirectusLPEvent[]): LPEvent[] => (events || []).map(mapLPEvent);

export const mapLPEventsID = (list?: DirectusLPEventID[]): LPEvent[] =>
  mapLPEvents((list || []).map((lp) => lp?.landing_page_events_id));

const resolveProductId = (productId: string) => (isCruise(productId) ? 'CM2C' : productId);

export const getProductEventsQuery = ({ locale, productId }: Params) => `{
  landing_pages (filter: { resort: { clubmed_id: { _eq: "${resolveProductId(productId)}"}}, status: { _eq: "published"}}) {
    resort_events (filter: {
      landing_page_events_id: {
          status: {
              _eq: "published"
          }
      }
    }) {
      landing_page_events_id (filter: { status: { _eq: "published" }}) {
        image {
          id
        }
        translations (filter: { languages_code: { code: { _eq: "${getLocale(locale)}"}}}) {
          description
          link
          link_label
          subtitle
          title
        }
      }
    }
  }
}`;

export const mapProductEvents = (res: DirectusLPData): LPEvent[] =>
  mapLPEventsID(res.data.landing_pages?.[0]?.resort_events);

export const getMEEventsQuery = ({ locale, productId }: Params) => `{
  landing_pages (filter: { resort: { clubmed_id: { _eq: "${resolveProductId(productId)}"}}, status: { _eq: "published"}}) {
    meeting_and_events (filter: { status: { _eq: "published" }}) {
      image {
        id
      }
      translations (filter: { languages_code: { code: { _eq: "${getLocale(locale)}"}}}) {
        description
        subtitle
        title
      }
      links {
        translations (filter: { languages_code: { code: { _eq: "${getLocale(locale)}"}}}) {
          label
          url
        }
      }
    }
  }
}`;

export const mapMEEvents = (res: DirectusLPData): LPEvent[] =>
  mapLPEvents(res.data.landing_pages?.[0]?.meeting_and_events);

export const getProductRestorationEventsQuery = ({ locale, productId }: Params) => `{
  landing_pages (filter: { resort: { clubmed_id: { _eq: "${resolveProductId(productId)}"}}, status: { _eq: "published"}}) {
    fnb_events (filter: {
      landing_page_events_id: {
          status: {
              _eq: "published"
          }
      }
    }) {
      landing_page_events_id {
        image {
          id
        }
        translations (filter: { languages_code: { code: { _eq: "${getLocale(locale)}"}}}) {
          description
          link
          link_label
          subtitle
          title
        }
      }
    }
  }
}`;

export const mapProductRestorationEvents = (res: DirectusLPData): LPEvent[] =>
  mapLPEventsID(res.data.landing_pages?.[0]?.fnb_events);

export const getProductOtherFacilitesEventsQuery = ({ locale, productId }: Params) => `{
  landing_pages (filter: { resort: { clubmed_id: { _eq: "${resolveProductId(productId)}"}}, status: { _eq: "published"}}) {
    other_facilities_events (filter: {
      landing_page_events_id: {
          status: {
              _eq: "published"
          }
      }
    }) {
      landing_page_events_id {
        image {
          id
        }
        translations (filter: { languages_code: { code: { _eq: "${getLocale(locale)}"}}}) {
          description
          link
          link_label
          subtitle
          title
        }
      }
    }
  }
}`;

export const mapProductOtherFacilitiesEvents = (res: DirectusLPData): LPEvent[] =>
  mapLPEventsID(res.data.landing_pages?.[0]?.other_facilities_events);
