import { Icon } from '@clubmed/trident-icons';
import { t } from 'i18next';
import { ComponentProps, FunctionComponent } from 'react';

import { Card } from '@/components/card/Card';
import { Heading } from '@/components/heading/Heading';
import { Image } from '@/components/image/Image';
import { Text } from '@/components/text/Text';
import { formatAPIISOTime, formatDuration, parseDurationAsHoursAndMinutes } from '@/helpers/dates';

type PartialCard = Partial<Omit<ComponentProps<typeof Card>, 'image'>>;

interface Props extends PartialCard {
  event: PlanningEvent;
  image?: Omit<ComponentProps<typeof Image>, 'src'>;
}

export const ActivityEvent: FunctionComponent<Props> = ({ event, ...props }) => {
  const duration = parseDurationAsHoursAndMinutes(event.startDateTime, event.endDateTime);

  return (
    <Card
      {...props}
      asHTMLText
      image={{
        ...props.image,
        src: event.image,
      }}
      text={event.description}
      title={`${formatAPIISOTime(event.startDateTime)} - ${formatAPIISOTime(event.endDateTime)}: ${event.label}`}
    >
      <div className="my-20 flex flex-col space-y-20">
        <Heading className="text-b2 font-semibold">{t('Text:text.activityDuration')}</Heading>
        <div className="flex">
          <Icon name="ClockDefault" width="24px" />
          <Text className="mx-20 text-b3">{formatDuration(duration)}</Text>
        </div>
      </div>
      <div className="my-20 flex flex-col space-y-20">
        <Heading className="text-b2 font-semibold">{t('Text:text.whereToFindIt')}</Heading>
        <div className="flex">
          <Icon name="Location" width="24px" />
          <Text className="mx-20 text-b3">{event.location}</Text>
        </div>
      </div>
    </Card>
  );
};
