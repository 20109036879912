import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from 'react';

interface Context {
  availableLocales: readonly string[];
  availableCruiseLocales: readonly string[];
}

export const AppContext = createContext<Context | null>(null);

interface Props {
  availableCruiseLocales?: string[];
  availableLocales?: string[];
}

export const AppProvider: FunctionComponent<PropsWithChildren<Props>> = ({
  availableCruiseLocales = [],
  availableLocales = [],
  children,
}) => {
  const value = useMemo(
    () => ({
      availableLocales,
      availableCruiseLocales,
    }),
    [],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};
