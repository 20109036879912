import { addDays } from 'date-fns';
import { FunctionComponent, HTMLAttributes, useEffect } from 'react';

import { PLANNING_ENDPOINT } from '@/api/APIClient';
import { mapProductPlanning } from '@/api/product-planning/productPlanningMapper';
import { Program } from '@/components/program/Program';
import { Skeleton } from '@/components/skeleton/Skeleton';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { formatDateToAPIDateTime, parseAPIDate } from '@/helpers/dates';
import { useAPI } from '@/hooks/use-service/useAPI';

const getProgramDates = (product: Product) => {
  if (product.isCruise) {
    return {
      first_date: formatDateToAPIDateTime(parseAPIDate(product.departureDate)),
      last_date: formatDateToAPIDateTime(parseAPIDate(product.arrivalDate)),
    };
  }
  const from = new Date();
  from.setHours(0, 0, 0, 0);
  const to = addDays(from, 6);
  return {
    first_date: formatDateToAPIDateTime(from),
    last_date: formatDateToAPIDateTime(to),
  };
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  product: Product;
}

export const ProductProgram: FunctionComponent<Props> = ({ product, ...props }) => {
  const { productId = '', locale = '' } = useRouterContext();

  const programDates = getProgramDates(product);

  const {
    data: dressCodes,
    error,
    fetch,
    isLoading,
  } = useAPI({ productId, locale, mapper: mapProductPlanning, url: PLANNING_ENDPOINT });

  useEffect(() => {
    if (productId) {
      fetch({
        queryParams: { ...programDates, date_format: 'LEGACY', type: 'DRESSCODE' },
      });
    }
  }, [fetch]);

  if (isLoading) {
    return <Skeleton className="aspect-square h-[370px] w-full rounded-16 md:w-[370px]" />;
  }

  if (error || !dressCodes) {
    return null;
  }

  return <Program {...props} dressCodes={dressCodes} />;
};
