import { ComponentProps, FunctionComponent, useEffect } from 'react';

import { getMEEventsQuery, mapMEEvents } from '@/api/events/events';
import { EventsCarousel } from '@/components/events-carousel/EventsCarousel';
import { SolidCardCarousel } from '@/components/solid-card-carousel/SolidCardCarousel';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { useDirectus } from '@/hooks/use-service/useDirectus';

export const ProductMeetingAndEvents: FunctionComponent<
  Omit<ComponentProps<typeof SolidCardCarousel>, 'items'>
> = (props) => {
  const { locale, productId } = useRouterContext();

  const { data: events, error, fetch, isLoading } = useDirectus({ mapper: mapMEEvents });

  useEffect(() => {
    if (productId && locale) {
      fetch({
        queryParams: {
          query: getMEEventsQuery({ locale, productId }),
        },
      });
    }
  }, [fetch]);

  return <EventsCarousel {...props} error={error} isLoading={isLoading} items={events || []} />;
};
