import { differenceInMinutes, format, parse, isSameMonth } from 'date-fns';

const API_FORMATS = {
  DATE: 'yyyyMMdd',
  DATE_TIME: "yyyyMMdd'T'HH:mm:ss.SSS",
  DATE_TIME_ISO: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  TIME: "HH:mm:ss'",
};

const APP_FORMATS = {
  TIME: 'kk:mm',
};

// parse
export const parseAPIDateTime = (str: string): Date => {
  return parse(str, API_FORMATS.DATE_TIME, new Date());
};

export const parseAPIDate = (str: string): Date => {
  return parse(str, API_FORMATS.DATE, new Date());
};

export const parseAPIDateTimeISO = (str: string): Date => {
  return parse(str, API_FORMATS.DATE_TIME_ISO, new Date());
};

export const parseAPITime = (str: string): Date => {
  return parse(str, API_FORMATS.TIME, new Date());
};

// format
export const formatAPITime = (time: string): string => format(parseAPITime(time), APP_FORMATS.TIME);

export const formatAPIISOTime = (time: string): string =>
  format(parseAPIDateTimeISO(time), APP_FORMATS.TIME);

export const formatDateToAPIDateTime = (date: Date): string => format(date, API_FORMATS.DATE_TIME);

export const parseDurationAsHoursAndMinutes = (start: DateTimeString, end: DateTimeString) => {
  const totalMinutes = differenceInMinutes(parseAPIDateTimeISO(end), parseAPIDateTimeISO(start));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
};

export function formatDuration(duration: { hours: number; minutes: number }): string {
  const hours = duration.hours > 0 ? `${duration.hours}h` : '';
  const minutes = duration.minutes > 0 ? `${duration.minutes}m` : '';

  if (hours && minutes) {
    return `${hours} ${minutes}`;
  } else {
    return hours || minutes;
  }
}
export const formatDateToAPIDate = (date: Date): string => format(date, API_FORMATS.DATE);

export const formatDateRange = (departureDate: string, arrivalDate: string): string => {
  // Parse the dates from 'YYYYMMDD' format
  const startDate = parse(departureDate, 'yyyyMMdd', new Date());
  const endDate = parse(arrivalDate, 'yyyyMMdd', new Date());

  // Check if the dates are in the same month
  if (isSameMonth(startDate, endDate)) {
    // Format as "DD to DD MMM"
    const startDay = format(startDate, 'dd');
    const endDay = format(endDate, 'dd');
    const month = format(startDate, 'MMM');
    return `${startDay} to ${endDay} ${month}`;
  } else {
    // Format as "DD MMM to DD MMM"
    const startFormatted = format(startDate, 'dd MMM');
    const endFormatted = format(endDate, 'dd MMM');
    return `${startFormatted} to ${endFormatted}`;
  }
};
