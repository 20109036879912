import { ComponentProps, FunctionComponent } from 'react';

import { Skeleton } from '@/components/skeleton/Skeleton';
import { SolidCard } from '@/components/solid-card/SolidCard';
import { SolidCardCarousel } from '@/components/solid-card-carousel/SolidCardCarousel';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { trackClick } from '@/helpers/tracking';

interface Props extends ComponentProps<typeof SolidCardCarousel> {
  error: Error | undefined;
  isLoading: boolean;
}

const getItemsWithTracking = (items: Omit<ComponentProps<typeof SolidCard>, 'labels'>[]) =>
  items.map((item) => ({
    ...item,
    links: item.links?.map((link) => ({
      ...link,
      onClick: () =>
        trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, {
          detail_click: 'learn_more',
        }),
    })),
  }));

export const EventsCarousel: FunctionComponent<Props> = ({ error, isLoading, ...props }) => {
  if (isLoading) {
    return <Skeleton className="aspect-square h-[10rem] w-full rounded-16" />;
  }

  if (error) {
    return null;
  }

  return (
    <SolidCardCarousel
      className="-mx-20 w-48 w-screen sm:mx-0 sm:w-full"
      CarouselBodyClassName="md:w-[924px]"
      spacingClassName="px-20 sm:px-0 gap-8 sm:gap-0"
      {...props}
      items={getItemsWithTracking(props.items)}
    />
  );
};
