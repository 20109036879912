export const mapFacilities = (facilities: APIFacilities): Facilities => {
  return facilities.map((data) => ({
    additionalDescription: data.additional_description,
    description: data.description,
    externalLinks: (data._links || [])
      .filter((l) => l.rel.startsWith('external_'))
      .map(({ href, label = '' }) => ({ href, label })),
    id: data.id,
    image: { src: data.images?.[0] },
    isExclusiveCollection: Boolean(data.exclusive_collection),
    name: data.label,
    onSite: data.on_site != false,
    openingDays: data.opening_days?.map((item) => ({
      day: item.day,
      hours: item.business_hours?.map((h) => ({
        closing: h.closing,
        label: h.label,
        opening: h.opening,
      })),
    })),
    ranking: data.ranking,
    reservationLink: data.reservation_link,
    specialAnnouncement: data.special_announcement,
    type: data.type,
  }));
};
