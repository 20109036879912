import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PRODUCTS_ENDPOINT } from '@/api/APIClient';
import { mapProducts } from '@/api/products/productsMapper';
import { CruiseForm } from '@/components/cruise-form/CruiseForm';
import { Loading } from '@/components/loading/Loading';
import { PageContainer } from '@/components/page-container/PageContainer';
import { TrackPage } from '@/components/track-page/TrackPage';
import { CRUISE } from '@/constants/product';
import { TRACK_PAGE_NAMES } from '@/constants/tracking';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { formatDateToAPIDate } from '@/helpers/dates';
import { useAPI } from '@/hooks/use-service/useAPI';

export const CruiseSelectorPage: FunctionComponent = () => {
  const { locale = '', productId = '' } = useRouterContext();
  const navigate = useNavigate();

  const {
    data: products,
    error: productsError,
    fetch: fetchProducts,
    isLoading: isProductLoading,
  } = useAPI({ productId, locale, mapper: mapProducts, url: PRODUCTS_ENDPOINT });

  useEffect(() => {
    fetchProducts({
      queryParams: {
        filter: [
          `category.id==${CRUISE}`,
          `trip_details.arrival_details.date>=${formatDateToAPIDate(new Date())}`,
        ],
        limit: 3,
      },
    });
  }, [fetchProducts]);

  if (isProductLoading) {
    return <Loading />;
  }

  const onCruiseSelect = (cruiseId: string) => {
    navigate(`/${locale}/${cruiseId}`, { replace: true });
  };

  if (productsError || !products) {
    navigate('/not-found', { replace: true });
    return null;
  }

  return (
    <main className="flex h-screen w-full items-center justify-center bg-lightSand" role="main">
      <TrackPage
        locale={locale}
        pageName={TRACK_PAGE_NAMES.CRUISE_SELECTOR_PAGE}
        params={{ resort_code: productId }}
      />
      <PageContainer className="max-w-[380px]">
        <CruiseForm products={products} onCruiseSelect={onCruiseSelect} />
      </PageContainer>
    </main>
  );
};
