import { t } from 'i18next';
import { Fragment, FunctionComponent } from 'react';

import { Frame } from '@/components/frame/Frame';
import { StaffLayer } from '@/components/staff-layer/StaffLayer';
import { useDisclosure } from '@/hooks/use-disclosure/useDisclosure';

interface Props {
  onClick?: () => void;
  staff: Staff;
}

export const Staff: FunctionComponent<Props> = ({ onClick, staff }) => {
  const [isOpen, { close, open }] = useDisclosure(false);

  if (!staff?.length) {
    return null;
  }

  const { picture } = staff[0];

  return (
    <Fragment>
      <Frame
        format="square"
        className="w-full"
        description={t('Titles:title.getToKnowTheGoTeam')}
        label={t('Titles:title.meetTheTeam')}
        onClick={() => {
          open();
          onClick?.();
        }}
        image={{
          src: picture,
          sizes:
            '(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1220px) 33vw, 380px',
        }}
      />
      {isOpen && <StaffLayer onClose={close} staff={staff} />}
    </Fragment>
  );
};
