import { Icon } from '@clubmed/trident-icons';
import { ChangeEvent, FunctionComponent, HTMLAttributes, useEffect, useState } from 'react';

import { classnames } from '@/helpers/classnames';

type Option = {
  id: string;
  label: string;
  value: string;
};

interface Props extends Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'> {
  innerClassName?: string;
  onChange?: (selectedValue: string) => void;
  options: Option[];
  placeholder?: string;
  selectedOption?: string;
}

export const Select: FunctionComponent<Props> = ({
  className,
  innerClassName,
  onChange,
  options,
  placeholder,
  selectedOption,
  ...props
}) => {
  const [selected, setSelected] = useState(selectedOption);

  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value: string = event.target.value;
    setSelected(value);
    onChange?.(value);
  };

  return (
    <div
      className={classnames(
        'relative',
        className,
        'isolate flex items-center overflow-hidden rounded-pill border border-lightGrey bg-white text-b3 font-normal text-black transition-colors duration-150 ease-in focus-within:border-black active:border-black [&:has(:focus-visible)]:ring',
      )}
    >
      <select
        {...props}
        className={classnames(
          'w-full appearance-none rounded-pill bg-transparent px-20 py-12 outline-none',
          innerClassName,
        )}
        data-name="Select"
        multiple={false}
        onChange={handleChange}
        value={selected}
      >
        {placeholder && (
          <option className="text-middleGrey" disabled value="">
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option {...option} key={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <Icon
        className="pointer-events-none absolute end-0 mx-20"
        name="ArrowDefaultDown"
        width="24px"
      />
    </div>
  );
};
