import { ComponentProps, Fragment, FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { AppFullScreen } from '@/components/app-full-screen/AppFullScreen';
import { Image } from '@/components/image/Image';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { trackClick } from '@/helpers/tracking';
import { useDisclosure } from '@/hooks/use-disclosure/useDisclosure';
import { useMediaQuery } from '@/hooks/use-media-query/useMediaQuery';

interface Props extends HTMLAttributes<HTMLDivElement> {
  map: Product['map'];
  thumbnail: ComponentProps<typeof Image>;
}

export const ProductMap: FunctionComponent<Props> = ({ map, thumbnail, ...props }) => {
  const { t } = useTranslation();
  const [isOpen, { open, close }] = useDisclosure(false);

  const track = () => trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, { detail_click: 'view_map' });

  const MapView = ({ map }: { map: Product['map'] }) => {
    const isSmallDevice = useMediaQuery('only screen and (max-width : 640px)');
    const imageComponent = <Image alt={t('Text:text.mapDescription')} src={map} />;

    return isSmallDevice ? (
      <TransformWrapper centerOnInit disablePadding maxScale={5}>
        <TransformComponent wrapperClass="!h-screen" wrapperStyle={{ height: '100dvh' }}>
          {imageComponent}
        </TransformComponent>
      </TransformWrapper>
    ) : (
      imageComponent
    );
  };

  return (
    <Fragment>
      <div {...props}>
        <button
          className="mb-20 block aspect-horizontal overflow-hidden rounded-16"
          onClick={() => {
            open();
            track?.();
          }}
          type="button"
        >
          <Image
            {...thumbnail}
            className="h-auto w-full object-cover"
            title={t('Buttons:button.viewTheItinerary')}
          />
        </button>
        <button
          className="text:black hover:text-middleGrey-active active:text-middleGrey-active focus:text-middleGrey-active border-b text-b3 font-semibold"
          onClick={() => {
            open();
            track?.();
          }}
          type="button"
        >
          {t('Buttons:button.viewTheItinerary')}
        </button>
      </div>
      {isOpen && (
        <AppFullScreen
          aria-label={t('Titles:title.map')}
          className="cursor-grab"
          labels={{ close: t('Buttons:close.button') }}
          onClose={close}
        >
          <MapView map={map} />
        </AppFullScreen>
      )}
    </Fragment>
  );
};
