import { IconsProvider as Provider } from '@clubmed/trident-icons';
import Actions from '@clubmed/trident-ui/atoms/Icons/svg/Actions';
import Brand from '@clubmed/trident-ui/atoms/Icons/svg/Brand';
import ResortOutline from '@clubmed/trident-ui/atoms/Icons/svg/ResortOutline';
import ResortOutlineEC from '@clubmed/trident-ui/atoms/Icons/svg/ResortOutline-EC';
import Transports from '@clubmed/trident-ui/atoms/Icons/svg/Transports';
import Utilities from '@clubmed/trident-ui/atoms/Icons/svg/Utilities';
import { FunctionComponent, PropsWithChildren } from 'react';

export const IconsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Provider icons={[Actions, Brand, ResortOutline, ResortOutlineEC, Transports, Utilities]}>
    {children}
  </Provider>
);
