import { ButtonAnchor } from '@clubmed/trident-ui/molecules/Buttons/ButtonAnchor';
import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandableText } from '@/components/expandable-text/ExpandableText';
import { Heading } from '@/components/heading/Heading';
import { OpeningDays } from '@/components/opening-days/OpeningDays';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { classnames } from '@/helpers/classnames';
import { trackClick } from '@/helpers/tracking';

interface Props extends HTMLAttributes<HTMLDivElement> {
  facility: Facility;
}

export const FacilityDetails: FunctionComponent<Props> = ({ className, facility, ...props }) => {
  const { t } = useTranslation();

  const {
    additionalDescription,
    description,
    externalLinks,
    openingDays,
    reservationLink,
    specialAnnouncement,
  } = facility;

  return (
    <div {...props} className={classnames(className, 'space-y-40')} data-name="FacilityDetails">
      {specialAnnouncement && (
        <div className="m-auto mb-60 max-w-[670px] rounded-16 border border-orange p-20">
          <Heading className="mb-12 text-b2 font-semibold">
            {t('Titles:title.specialAnnouncement')}
          </Heading>
          <p>{specialAnnouncement}</p>
        </div>
      )}
      <div className="flex flex-col items-start gap-20 md:flex-row">
        <div className="space-y-12">
          <ExpandableText
            asHTML={Boolean(additionalDescription)}
            className="max-w-[380px]"
            labels={{
              expand: t('Buttons:expandtext.button'),
              reduce: t('Buttons:collapseText.button'),
            }}
          >
            {additionalDescription || description}
          </ExpandableText>
        </div>
        {externalLinks.map((el) => (
          <ButtonAnchor
            data-testid="externalLinks"
            href={el.href}
            key={el.href}
            onClick={() =>
              trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, { detail_click: 'click_link' })
            }
            target="_blank"
            theme="blackStroke"
          >
            {el.label}
          </ButtonAnchor>
        ))}
        {reservationLink && (
          <ButtonAnchor
            data-testid="reservationLink"
            href={reservationLink}
            icon="ArrowTailRight"
            onClick={() =>
              trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, { detail_click: 'book_table' })
            }
            target="_blank"
          >
            {t('Buttons:button.bookATable')}
          </ButtonAnchor>
        )}
      </div>
      <OpeningDays days={openingDays} />
    </div>
  );
};
