import { FunctionComponent, HTMLAttributes } from 'react';

import { Frame } from '@/components/frame/Frame';
import { classnames } from '@/helpers/classnames';

interface Props extends HTMLAttributes<HTMLDivElement> {
  staff: Staff;
}

export const StaffMembers: FunctionComponent<Props> = ({ className, staff, ...props }) => {
  if (!staff?.length) {
    return null;
  }

  return (
    <section
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="StaffMembers"
    >
      {staff.map(({ picture, job, firstName }) => (
        <Frame
          format="square"
          className="w-full"
          description={job}
          key={firstName}
          label={firstName}
          image={{
            src: picture,
            sizes:
              '(max-width: 640px) 100vw, (max-width: 768px) 50vw, (max-width: 1220px) 33vw, 380px',
          }}
        />
      ))}
    </section>
  );
};
