import { Icon } from '@clubmed/trident-icons';
import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Select } from '@/components/select/Select';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useApp } from '@/contexts/AppContext';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { classnames } from '@/helpers/classnames';
import { getLanguages } from '@/helpers/languages';
import { isCruise } from '@/helpers/product';
import { trackClick } from '@/helpers/tracking';

export const LanguagesForm: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const { locale = 'en-GB', productId = '' } = useRouterContext();
  const { availableCruiseLocales, availableLocales } = useApp();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>(locale);

  const languages = (isCruise(productId) ? availableCruiseLocales : availableLocales).map(
    (lang) => ({
      id: lang,
      value: lang,
      label: getLanguages(t, productId)[lang],
    }),
  );

  return (
    <div
      {...props}
      className={classnames('relative', className, 'isolate')}
      data-name="LanguagesForm"
    >
      <div className="absolute start-1/2 top-[-25%] aspect-square h-[17.5rem] -translate-x-1/2 rounded-full bg-saffron" />
      <Icon
        className="absolute start-1/2 top-0 z-2 -mt-[83px] -translate-x-1/2 text-ultramarine"
        name="Trident"
        width="7.5rem"
      />
      <div className="relative z-1 flex flex-col gap-20 rounded-16 border border-saffron bg-white px-20 py-60">
        <label className="mb-20 block text-center font-serif text-h4" htmlFor="languages">
          {t('Text:language.Title')}
        </label>
        <Select
          id="languages"
          className="h-48"
          innerClassName="h-48"
          onChange={setSelectedLanguage}
          options={languages}
          placeholder={t('Text:language.SelectYourLanguage')}
          selectedOption={selectedLanguage}
        />
        {selectedLanguage && (
          <Button
            label={t('Buttons:validate.Button', { lng: selectedLanguage })}
            onClick={() => {
              trackClick(TRACK_EVENT_NAMES.CLICK_LANGUAGE_CHOICE, { detail_click: 'validate' });
              navigate(`/${selectedLanguage}/${productId}`, { replace: true });
            }}
            theme="yellow"
          />
        )}
      </div>
    </div>
  );
};
