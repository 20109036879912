import { groupBy } from 'lodash';
import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DayPlanningLayer } from '@/components/day-planning-layer/DayPlanningLayer';
import { Frame } from '@/components/frame/Frame';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { classnames } from '@/helpers/classnames';
import { parseAPIDateTime } from '@/helpers/dates';

interface Props extends HTMLAttributes<HTMLDivElement> {
  dressCodes: Planning;
}

export const Program: FunctionComponent<Props> = ({ className, dressCodes, ...props }) => {
  const [active, setActive] = useState<Planning | null>(null);

  const { t } = useTranslation();
  const { locale } = useRouterContext();

  if (!dressCodes?.length) {
    return null;
  }

  const groups = groupBy(dressCodes, (d) => d.startDateTime);

  return (
    <div
      {...props}
      className={classnames(className, 'grid grid-cols-1 gap-20 sm:grid-cols-2 md:grid-cols-3')}
      data-name="Program"
    >
      {Object.values(groups).map((group) => (
        <Frame
          description={t('Titles:title.dresscodeAndActivities')}
          format="square"
          image={{
            src: group[0].image,
          }}
          key={group[0].id}
          label={parseAPIDateTime(group[0].startDateTime)
            .toLocaleString(locale, {
              weekday: 'long',
            })
            .toUpperCase()}
          onClick={() => setActive(group)}
        />
      ))}
      {active && <DayPlanningLayer dressCodes={active} onClose={() => setActive(null)} />}
    </div>
  );
};
