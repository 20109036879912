import i18next from 'i18next';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useNavigate, Routes, useParams } from 'react-router-dom';

import { useApp } from '@/contexts/AppContext';
import { AppRouteProvider } from '@/contexts/AppRouterContext';
import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/localStorage';
import { isCruise } from '@/helpers/product';
import { CruiseSelectorPage } from '@/pages/CruiseSelectorPage/CruiseSelectorPage';
import { LanguageSelectorPage } from '@/pages/LanguageSelectorPage/LanguageSelectorPage';
import { NotFoundPage } from '@/pages/NotFoundPage/NotFoundPage';
import { ProductPage } from '@/pages/ProductPage/ProductPage';

const LanguageSelectorRoute = () => {
  const { productId = '' } = useParams();

  const storedLocale = getLocalStorageItem('selectedLanguage');
  const navigate = useNavigate();

  useEffect(() => {
    if (storedLocale && !isCruise(productId)) {
      navigate(`/${storedLocale}/${productId}`, { replace: true });
      return;
    }
  }, [productId]);

  return <LanguageSelectorPage />;
};

const ProductRoute = () => {
  const { locale = '', productId = '' } = useParams();
  const navigate = useNavigate();
  const { availableLocales } = useApp();
  const isLocaleValid = availableLocales.includes(locale);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!isLocaleValid) {
      navigate('/not-found', { replace: true });
      return;
    }
    const storedLocale = getLocalStorageItem('selectedLanguage');
    if (locale !== storedLocale || i18next.language !== locale) {
      i18n.changeLanguage(locale);
      setLocalStorageItem('selectedLanguage', locale);
    }
  }, [isLocaleValid, locale]);

  if (!isLocaleValid) {
    return null;
  }

  if (productId.toUpperCase() === 'CM2C') return <CruiseSelectorPage />;

  return <ProductPage />;
};

export const LocaleRouter: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<AppRouteProvider />}>
        <Route path=":productId" element={<LanguageSelectorRoute />} />
        <Route path=":locale/:productId" element={<ProductRoute />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
