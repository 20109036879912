import { FunctionComponent, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

import { GoToTop } from '@/components/go-to-top/GoToTop';
import { PORTAL_IDS } from '@/constants/html';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { IconsProvider } from '@/contexts/IconsContext';

export const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { locale } = useRouterContext();
  return (
    <IconsProvider>
      <Helmet
        htmlAttributes={{
          dir: 'ltr',
          lang: locale,
        }}
      >
        <title>ClubMed</title>
      </Helmet>
      {children}
      <GoToTop />
      <div id={PORTAL_IDS.MAIN_PORTAL} className="relative isolate z-1" />
    </IconsProvider>
  );
};
