import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { isEmpty } from 'lodash';

export interface EndpointMap {
  [key: string]: string;
}

export interface QueryParams {
  [key: string]: string | number | boolean | string[];
}

export interface ErrorResponse {
  status_code: number;
  error: string;
  error_description: string;
  errors: ErrorDetail[];
}

interface Error {
  response: { data: ErrorResponse };
}

interface ErrorDetail {
  error_code: string;
  concerned_key: string;
  error_description: string;
}

interface RequestOptions {
  endpoint: string;
  cache?: boolean;
  params?: { [key: string]: string };
  queryParams?: QueryParams;
  config?: AxiosRequestConfig;
}

interface DataRequestOptions<D> {
  endpoint: string;
  cache?: boolean;
  params?: { [key: string]: string };
  data?: D;
  queryParams?: QueryParams;
  config?: AxiosRequestConfig;
}

const generateCacheBuster = (): string => {
  return `${new Date().getTime()}`;
};

export class AxiosService {
  private instance: AxiosInstance;
  private baseURL: string;
  private endpoints: EndpointMap;

  constructor({
    baseURL,
    endpoints,
    apiKey,
    headers,
  }: {
    baseURL: string;
    endpoints: EndpointMap;
    apiKey: string;
    headers: { [key: string]: string };
  }) {
    if (!apiKey) {
      throw new Error('INVALID API KEY');
    }
    this.baseURL = baseURL;
    this.endpoints = endpoints;
    this.instance = axios.create({
      baseURL,
      headers,
      params: { api_key: apiKey },
      withCredentials: true,
    });

    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        return config;
      },
      (error: unknown) => {
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response.data;
      },
      (error: Error) => {
        return Promise.reject({
          status_code: error.response?.data?.status_code,
          error: error.response?.data?.error,
          error_description: error.response?.data?.error_description,
          errors: error.response?.data?.errors,
        });
      },
    );
  }

  private buildQueryParams(params?: QueryParams, cache?: boolean): string {
    const cacheBuster = generateCacheBuster();
    const extendedParams: QueryParams = { ...(params || {}), ...(!cache ? { cacheBuster } : {}) };
    if (isEmpty(extendedParams)) return '';
    const queryParams = Object.keys(extendedParams)
      .flatMap((key) => {
        const value = extendedParams[key];
        if (Array.isArray(value)) {
          return value.map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(String(item))}`,
          );
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
        }
      })
      .join('&');
    return `?${queryParams}`;
  }

  private replacePlaceholders(endpoint: string, params?: { [key: string]: string }): string {
    const endpointPath = this.endpoints[endpoint.toUpperCase()];
    if (!endpointPath) {
      throw new Error(`Endpoint not found: ${endpoint}`);
    }
    let url = endpointPath;
    Object.keys(params || []).forEach((key) => {
      url = url.replace(`{${key}}`, params?.[key] || '');
    });
    return url;
  }

  public async get<T>({
    endpoint,
    cache,
    params,
    queryParams,
    config,
  }: RequestOptions): Promise<T | ErrorResponse> {
    const url = `${this.replacePlaceholders(endpoint, params)}${this.buildQueryParams(queryParams, cache)}`;
    return await this.instance.get(url, config);
  }

  public async post<D, T>({
    endpoint,
    cache,
    params,
    data,
    queryParams,
    config,
  }: DataRequestOptions<D>): Promise<T | ErrorResponse> {
    const url = `${this.replacePlaceholders(endpoint, params)}${this.buildQueryParams(queryParams, cache)}`;
    return await this.instance.post(url, data, config);
  }

  public async put<D, T>({
    endpoint,
    cache,
    params,
    data,
    queryParams,
    config,
  }: DataRequestOptions<D>): Promise<T | ErrorResponse> {
    const url = `${this.replacePlaceholders(endpoint, params)}${this.buildQueryParams(queryParams, cache)}`;
    return await this.instance.put(url, data, config);
  }

  public async delete<T>({
    endpoint,
    cache,
    params,
    queryParams,
    config,
  }: RequestOptions): Promise<T | ErrorResponse> {
    const url = `${this.replacePlaceholders(endpoint, params)}${this.buildQueryParams(queryParams, cache)}`;
    return await this.instance.delete(url, config);
  }
}
