import { Icon } from '@clubmed/trident-ui/atoms/Icons/Icon';
import { Button } from '@clubmed/trident-ui/molecules/Buttons/Button';
import { FunctionComponent, HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from '@/components/select/Select';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { classnames } from '@/helpers/classnames';
import { formatDateRange } from '@/helpers/dates';
import { trackClick } from '@/helpers/tracking';

interface Props extends HTMLAttributes<HTMLDivElement> {
  onCruiseSelect: (cruiseId: string) => void;
  products: Products;
}

export const CruiseForm: FunctionComponent<Props> = ({
  className,
  onCruiseSelect,
  products,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedCruise, setSelectedCruise] = useState<string | undefined>(products?.[0]?.id);
  const cruises = products.map(({ id, name, departureDate, arrivalDate }) => ({
    id,
    value: id,
    label: `${formatDateRange(departureDate, arrivalDate)} - ${name}`,
  }));

  return (
    <div {...props} className={classnames('relative', className, 'isolate')} data-name="CruiseForm">
      <div className="absolute start-1/2 top-[-25%] aspect-square h-[17.5rem] -translate-x-1/2 rounded-full bg-saffron" />
      <Icon
        className="absolute start-1/2 top-0 z-2 -mt-[83px] -translate-x-1/2 text-ultramarine"
        name="Trident"
        width="7.5rem"
      />
      <div className="relative z-1 flex flex-col gap-20 rounded-16 border border-saffron bg-white px-20 py-60">
        <label className="mb-20 block text-center font-serif text-h4" htmlFor="cruise">
          {t('Select your cruise and dates')}
        </label>
        <Select
          id="cruises"
          className="h-72"
          innerClassName="whitespace-normal pr-40 h-72"
          onChange={setSelectedCruise}
          options={cruises}
          placeholder={t('SelectYourCruise')}
          selectedOption={selectedCruise}
        />
        {selectedCruise && (
          <Button
            label={t('Buttons:validate.Button')}
            onClick={() => {
              onCruiseSelect(selectedCruise);
              trackClick(TRACK_EVENT_NAMES.CLICK_CRUISE_CHOICE, { detail_click: 'validate' });
            }}
            theme="yellow"
          />
        )}
      </div>
    </div>
  );
};
