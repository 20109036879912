import { Icon } from '@clubmed/trident-icons';
import { ButtonAnchor } from '@clubmed/trident-ui/molecules/Buttons/ButtonAnchor';
import { FunctionComponent, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '@/components/heading/Heading';
import { Text } from '@/components/text/Text';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useExperience } from '@/contexts/ExperienceContext';
import { classnames } from '@/helpers/classnames';
import { getIconName } from '@/helpers/product';
import { trackClick } from '@/helpers/tracking';

interface Props extends HTMLAttributes<HTMLDivElement> {
  product: Product;
}

export const ProductBrochure: FunctionComponent<Props> = ({ className, product, ...props }) => {
  const { t } = useTranslation();
  const { isECMode } = useExperience();

  const iconName = getIconName({
    productId: product.id,
    isExclusiveCollection: product.comfort.isExclusiveCollection,
    isECMode,
  });

  return (
    <div
      {...props}
      className={classnames(className, 'justify-between overflow-hidden bg-ultramarine text-white')}
    >
      <div className="relative z-0 max-w-1220 md:mx-auto md:flex md:items-center md:justify-between">
        <div className="relative z-1 gap-20 space-y-12 p-20 pe-40 md:min-w-360 md:pb-80 md:pe-20 md:pt-40">
          <Heading className="font-serif text-h4 md:text-h3">
            {t('Titles:title.stillHaveQuestions')}
          </Heading>
          <Text className="pb-20 text-b3 md:pb-8">
            {t('Text:text.informationAboutTheResortBrochure')}
          </Text>
          <div className="!mt-20">
            <ButtonAnchor
              data-testid="factSheetAnchor"
              href={product.factSheetUrl}
              onClick={() =>
                trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, { detail_click: 'get_the_brochure' })
              }
              target="_blank"
              theme="yellow"
            >
              {t('Buttons:button.getTheBrochure')}
            </ButtonAnchor>
          </div>
        </div>
        <div className="-mb-20 -mt-20 ms-auto flex h-[410px] w-[240px] shrink-0 items-center justify-center self-stretch pe-40 md:mb-0 md:me-20 md:h-auto md:w-[510px] md:pb-20 md:pe-0 md:[contain:paint]">
          <div className="w-[510px] shrink-0 md:absolute">
            <Icon
              name={iconName}
              type="svg"
              className="force-white h-full w-full object-cover"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
