import { hasECSpace, isExclusiveCollection } from '@/helpers/product';

export const mapProduct = (product: APIProduct): Product => {
  const { category, comfort, description_2, media, resort_id, title, type, trip_details } = product;

  return {
    id: resort_id,
    arrivalDate: trip_details?.arrival_details?.date,
    comfort: {
      hasExclusiveSpace: hasECSpace(product),
      isExclusiveCollection: isExclusiveCollection({ level: comfort?.level, type: type.id }),
      level: comfort?.level,
    },
    country: product.destination?.countries?.[0],
    departureDate: trip_details?.departure_details?.date,
    description: description_2,
    factSheetUrl: media.fact_sheet_url,
    isCruise: category.id === 'cruise',
    map: media.map,
    name: title,
  };
};
