import { FunctionComponent, useEffect } from 'react';

import { STAFF_ENDPOINT } from '@/api/APIClient';
import { mapStaff } from '@/api/staff/staffMapper';
import { Skeleton } from '@/components/skeleton/Skeleton';
import { Staff } from '@/components/staff/Staff';
import { TRACK_EVENT_NAMES } from '@/constants/tracking';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { trackClick } from '@/helpers/tracking';
import { useAPI } from '@/hooks/use-service/useAPI';

export const ProductStaff: FunctionComponent = () => {
  const { productId = '', locale = '' } = useRouterContext();

  const {
    data: staff,
    error,
    fetch,
    isLoading,
  } = useAPI({ productId, locale, mapper: mapStaff, url: STAFF_ENDPOINT });

  useEffect(() => {
    fetch();
  }, []);

  if (isLoading) {
    return <Skeleton className="aspect-square h-[350px] w-full rounded-16" />;
  }

  if (error || !staff) {
    return null;
  }

  return (
    <Staff
      onClick={() => trackClick(TRACK_EVENT_NAMES.CLICK_LANDING, { detail_click: 'meet_the_team' })}
      staff={staff}
    />
  );
};
