import { FunctionComponent, HTMLAttributes } from 'react';

import { ProductBrochure } from '@/components/product-brochure/ProductBrochure';
import { ProductEvents } from '@/components/product-events/ProductEvents';
import { ProductMap } from '@/components/product-map/ProductMap';
import { ProductMeetings } from '@/components/product-meetings/ProductMeetings';
import { ProductStaff } from '@/components/product-staff/ProductStaff';

interface Props extends HTMLAttributes<HTMLDivElement> {
  product: Product;
}

export const ProductOverview: FunctionComponent<Props> = ({ className, product, ...props }) => (
  <section {...props} className={className} data-name="ProductOverview">
    <div className="grid grid-cols-1 gap-x-20 gap-y-40 md:grid-cols-3">
      {!product.isCruise && <ProductMeetings />}
      {product.map && (
        <ProductMap
          map={product.map}
          thumbnail={{
            src: product.map,
          }}
        />
      )}
      <div className="md:order-first md:col-span-3">
        <ProductEvents />
      </div>
      <ProductStaff />
    </div>
    {product.isCruise && (
      <div className="mt-20">
        <ProductBrochure product={product} />
      </div>
    )}
  </section>
);
