import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Select } from '@/components/select/Select';
import { useApp } from '@/contexts/AppContext';
import { useRouterContext } from '@/contexts/AppRouterContext';
import { getLanguages } from '@/helpers/languages';
import { isCruise } from '@/helpers/product';

interface Props {
  className?: string;
}

export const SelectLanguages: FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { locale, productId } = useRouterContext();
  const navigate = useNavigate();
  const { availableCruiseLocales, availableLocales } = useApp();

  const onChange = (language: string) => {
    navigate(`/${language}/${productId}`);
  };

  const languages = (isCruise(productId) ? availableCruiseLocales : availableLocales).map(
    (lang) => ({
      id: lang,
      value: lang,
      label: getLanguages(t, productId)[lang],
    }),
  );

  return (
    <Select
      className={className}
      id="languages"
      onChange={onChange}
      options={languages}
      placeholder={t('Text:language.SelectYourLanguage')}
      selectedOption={locale}
    />
  );
};
