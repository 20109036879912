import { TFunction } from 'i18next';

import { isCruise } from './product';

export const getLanguages = (t: TFunction, productId: string): Record<string, string> =>
  !isCruise(productId)
    ? {
        'en-GB': t('Text:language.english', { lng: 'en-GB' }),
        'en-US': t('Text:language.english', { lng: 'en-GB' }),
        'fr-FR': t('Text:language.french', { lng: 'fr-FR' }),
        'fr-CA': t('Text:language.frenchCanada', { lng: 'fr-FR' }),
        'ja-JP': t('Text:language.japanese', { lng: 'ja-JP' }),
        'pt-BR': t('Text:language.portuguese', { lng: 'pt-BR' }),
        'pt-PT': t('Text:language.portuguese', { lng: 'pt-BR' }),
      }
    : {
        'en-GB': t('Text:language.english', { lng: 'en-GB' }),
        'en-US': t('Text:language.english', { lng: 'en-GB' }),
        'fr-FR': t('Text:language.cruise.french', { lng: 'fr-FR' }),
      };
