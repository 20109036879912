/* eslint-disable import/no-named-as-default-member */
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'en-GB',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['Buttons', 'Schedules', 'Tabs', 'Text', 'Titles'],
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
    lng: navigator.language || 'en-GB',
    nonExplicitSupportedLngs: false,
    preload: ['en-GB', 'fr-CA', 'fr-FR', 'ja-JP', 'pt-BR'],
    load: 'currentOnly',
  });

export default i18next;
