import { ComponentProps, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Carousel,
  CarouselBody,
  CarouselControlNext,
  CarouselControlPrevious,
} from '@/components/carousel/Carousel';
import { SolidCard } from '@/components/solid-card/SolidCard';
import { classnames } from '@/helpers/classnames';

interface Props extends ComponentProps<typeof Carousel> {
  CarouselBodyClassName?: string;
  itemClassName?: string;
  items: Omit<ComponentProps<typeof SolidCard>, 'labels'>[];
  spacingClassName?: string;
}

export const SolidCardCarousel: FunctionComponent<Props> = ({
  className,
  CarouselBodyClassName,
  itemClassName,
  items,
  spacingClassName,
  ...props
}) => {
  const { t } = useTranslation();

  const validItems = items.filter(({ title }) => Boolean(title));

  if (!validItems?.length) {
    return null;
  }

  return (
    <Carousel {...props} className={classnames(className, 'flex items-center gap-20')}>
      <CarouselBody
        className={CarouselBodyClassName}
        itemClassName={itemClassName}
        spacingClassName={classnames('items-stretch', spacingClassName)}
      >
        {validItems.map((item) => (
          <SolidCard
            labels={{
              close: t('Buttons:close.button'),
              expand: t('Buttons:expandtext.button'),
              reduce: t('Buttons:collapseText.button'),
            }}
            {...item}
            key={item.title}
          />
        ))}
      </CarouselBody>
      <CarouselControlPrevious
        className="order-first md:inline-block"
        label={t('Buttons:controls.previous')}
      />
      <CarouselControlNext className="md:inline-block" label={t('Buttons:controls.next')} />
    </Carousel>
  );
};
