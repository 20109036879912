import { createContext, FunctionComponent, useContext, useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Layout } from '@/pages/Layout/Layout';
interface Context {
  locale: string;
  productId: string;
}

export const AppRouterContext = createContext<Context | null>(null);

export const AppRouteProvider: FunctionComponent = () => {
  const location = useLocation();
  const params = useParams();

  const value = useMemo(() => {
    const { locale = 'en-GB', productId = '' } = params;
    return { locale, productId, currentPath: location.pathname };
  }, [params, location.pathname]);

  return (
    <AppRouterContext.Provider value={value}>
      <Layout>
        <Outlet />
      </Layout>
    </AppRouterContext.Provider>
  );
};

export const useRouterContext = () => {
  const context = useContext(AppRouterContext);
  if (!context) {
    throw new Error('useRouterContext must be used within a AppProvider');
  }
  return context;
};
